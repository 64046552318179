import * as React from "react";
import { keyframes } from "@stitches/react";
import { observer } from "mobx-react";
import { defaultColors, styled, css } from "../../../stitches.config";
import { useStores } from "../../../helpers/useStores";
import Link from "../../../components/Link";
import Logo from "../../atoms/Logo";

import SocialButtonLike from "../../atoms/SocialButtonLike"
import SocialButtonShare from "../../atoms/SocialButtonShare"
import SocialButtonComment from "../../atoms/SocialButtonComment"

import { NavbarFixedProps } from "./NavbarFixed"
import NavLogo from "./NavLogo"

import { IS_FEATURE_enableComments } from "../../../components/common/constants/constants";

const NavbarHidingArticle: React.FC<NavbarFixedProps> = observer(({ articleProgress = 0 }) => {

    const { ArticleStore } = useStores();
    const url = ArticleStore?.article?.fb_url;

    return (
        <NavWrap>
            <NavWrapTop>
                <Link to="/" data-analytics-name="nav-main-logo-hiding-article">
                    <NavLogo
                        className="navigation-top--logo"
                        brand={window.BRAND as any}
                        type="dark"
                    />
                </Link>
                <Options>
                    <SocialButtonLike isNavArticle={true} url={url} />
                    {IS_FEATURE_enableComments && <SocialButtonComment isNavArticle={true} />}
                    <SocialButtonShare isNavArticle={true} url={url} />
                </Options>
            </NavWrapTop>
            <ProgressBar css={{
                "&:after": { width: `${articleProgress}%` }
            }} />
        </NavWrap>
    );
});

const scrollDown = keyframes({
    "0%": { transform: "translateY(-100%)" },
    "100%": { transform: "translateY(0)" },
});

// nie jest przekazywany props z wysokością artykułu
const ProgressBar = styled("div", {
    backgroundColor: "transparent",
    height: "2px",
    position: "relative",
    "&:after": {
        transition: "all 0.1s linear",
        content: "",
        position: "absolute",
        left: 0,
        width: 0,
        top: 0,
        bottom: 0,
        height: "2px",
        backgroundColor: "$primary",
    }
});

const Options = styled("div", {
    display: "flex",
    paddingRight: "10px",
    "& button, a, .social-btn-share": {
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 0 0 12px",
        borderWidth: "0px",
        width: "30px",
    },
    ".social-btn-share": {
        position: "static",
    },
    "span": {
        marginRight: "6px",
    }
});

const NavWrap = styled("div", {
    height: 54,
    width: "100%",
    backgroundColor: defaultColors.grayscale100,
    animation: `${scrollDown} 0.6s`,
});

const NavWrapTop = styled("div", {
    height: 52,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    ".navigation-top--logo": {
        marginLeft: "20px",
        "@bp4": {
            marginLeft: 0,
        }
    }
});

export default NavbarHidingArticle;

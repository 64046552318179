import * as React from "react";
import { useLocation } from "react-router-dom";

import {
  IS_FEATURE_adunityPartner
} from "../../../components/common/constants/constants"

import { defaultColors, styled } from "../../../stitches.config";
import { useScroll, useStores } from "../../../helpers";

import Adslot from "../../../components/Adslot";
import NavbarHidingDefault from "./NavbarHidingDefault";
import NavbarHidingArticle from "./NavbarHidingArticle"

export interface NavbarFixedProps {
  showNavArticle?: boolean,
  articleProgress?: number
}

const NavbarFixed: React.FC<NavbarFixedProps> = ({ showNavArticle = false, articleProgress = 0 }) => {
  const { direction, prevOffset } = useScroll();
  const { pathname } = useLocation();

  const { UIStore } = useStores();
  const isMaterialPartnera = UIStore.isMaterialPartnera;
  const suffix = ((IS_FEATURE_adunityPartner && isMaterialPartnera) ? '_partner' : '');

  const getPagePrefix = (pathname: string) => {
    if (pathname === "/") {
      return "sg";
    } else if (pathname.lastIndexOf("/c/", 0) === 0) {
      return "kat";
    } else if (/^(\/(blogi\/\w+|zdrowie))?\/\d{1,},/.test(pathname)) {
      return "art";
    } else {
      return null;
    }
  };

  const prefix = getPagePrefix(pathname);

  return (
    <NavFixedWrap>
      {!!prefix && <Adslot name={prefix + '_belka_top' + suffix} />}
      {direction === "up" && prevOffset > 180 &&
        (showNavArticle ? <NavbarHidingArticle articleProgress={articleProgress} /> : <NavbarHidingDefault />)
      }
    </NavFixedWrap>
  );
};

const NavFixedWrap = styled("div", {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  zIndex: 99999,
});

export default NavbarFixed;
